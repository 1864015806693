var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"d-flex flex-column p-3",staticStyle:{"row-gap":"16px"},attrs:{"no-body":""}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.handleAction}},[_vm._v("Print")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.handleAction}},[_vm._v("Excel")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"small":"","show-empty":"","filter":_vm.filter,"striped":"","head-variant":"light","items":_vm.employees,"fields":[
                    { key: 'customerId', label: 'Customer ID' },
                    { key: 'nationalID', label: 'National ID' },
                    { key: 'firstName', label: 'First Name' },
                    { key: 'lastName', label: 'Last Name' },
                    { key: 'jobDescription', label: 'Job Description' },
                    { key: 'monthlySalary', label: 'Salary' },
                    { key: 'isActive', label: 'Active' },
                    { key: 'isVerified', label: 'Verified' },
                    { key: 'action', label: 'View Profile' } ]},scopedSlots:_vm._u([{key:"cell(isActive)",fn:function(data){return [(data.item.isActive)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Active")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Inactive")])]}},{key:"cell(isVerified)",fn:function(data){return [(data.item.isVerified)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Verified")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Not Verified")])]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handleView(data.item)}}},[_vm._v("View Detail")])]}}])}),_c('div',{staticClass:"pt-2 d-flex align-items-center"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Per page","label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"9","label-size":"sm","label-class":"fw-medium"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('div',{staticClass:"ml-auto"},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.employees.length,"per-page":_vm.perPage,"align":"fill"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }