<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="mb-4">
                <b-card-body class="p-3">
                    <b-tabs content-class="mt-3" align="left">
                        <b-tab title="Main" active>
                            <Main />
                        </b-tab>

                        <b-tab title="Documents">
                            <ScannedDocuments />
                        </b-tab>

                        <b-tab title="Comments">
                            <Comments />
                        </b-tab>

                        <b-tab title="Employees">
                            <Employees />
                        </b-tab>
                    </b-tabs>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Main from '@/components/customers/Main.vue';
import ScannedDocuments from '@/components/customers/ScannedDocuments.vue';
import Comments from '@/components/customers/Comments.vue';
import Employees from '../../components/customers/Employees.vue';

export default {
    name: 'CustomerDetail',
    components: {
        Main,
        ScannedDocuments,
        Comments,
        Employees,
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async getData() {
            this.loading = true;
            await this.$store.dispatch('customer/get_customer', this.$route.params.customer);
            await this.$store.dispatch('customer/productRequest/get_processResquest', this.$route.params.customer);
            this.loading = false;
        },
    },
    created() {
        this.getData();
    },
};
</script>
